<template>
  <div class="contentValidationRegie">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style  bg-select"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Semaine</span>
          </div>

          <b-form-select
            class="b-form-select-new-style  bg-select"
            v-model="filterSemaine"
            :options="ComputedListWeek"
            @change="handleChange"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Régie</span>
          </div>

          <SelectComponent
            :options="computedgetAllRegie"
            :value="filterRegie"
            label="name"
            champName="régie"
            filterName="filterRegie"
            :change="handleChange"
            :track-by="'name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style w-1  bg-select-vue"
          />
        </div>

        <div v-if="getLoadingValidationCoutRegie" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <b-button
            v-if="cantExport(this.$options.name)"
            size="sm"
            variant="light"
            class="button-default-style bg-select  ml-2"
            @click="exportFiles"
            >Export</b-button
          >
        </div>
      </div>
    </div>
    <div class="body-box-rapport">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style tableValidationCouRegie  table-header-regie"
          :items="computedRegieFacture"
          :fields="fields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(regie_name)="data">
            {{ data.item.regie_name }}
          </template>
          <template v-slot:cell(total_surface_101)="data">
            {{ data.item.total_surface_101 }} m²
          </template>
          <template v-slot:cell(total_surface_102)="data">
            {{ data.item.total_surface_102 }} m²
          </template>
          <template v-slot:cell(total_surface_103)="data">
            {{ data.item.total_surface_103 }} m²
          </template>
          <template v-slot:cell(montant_101)="data">
            {{ data.item.montant_101 }} €
          </template>
          <template v-slot:cell(montant_102)="data">
            {{ data.item.montant_102 }} €
          </template>
          <template v-slot:cell(montant_103)="data">
            {{ data.item.montant_103 }} €
          </template>
          <template v-slot:cell(total)="data">
            {{ data.item.total }} €
          </template>
          <template v-slot:cell(pourcentage)="data">
            {{ data.item.pourcentage }}
          </template>
          <template v-slot:cell(total_tasks)="data">
            {{ data.item.total_tasks }}
          </template>
          <template v-slot:cell(totalm2_totalEuro)="data">
            {{ data.item.totalm2_totalEuro }} €
          </template>

          <template v-slot:cell(statut)="data">
            {{ data.item.statue | statutFormat }}
          </template>
          <template v-slot:cell(semaine)="data">
            {{ data.item.semaine }}
          </template>
          <template v-slot:cell(payed_at)="data">
            {{ data.item.payed_at }}
          </template>
          <template v-slot:cell(commentaire)="data">
            {{ data.item.commentaire }}
          </template>
          <template #table-caption v-if="getRegieValidationCoutTable.length">
            <b-tr class=" tr-generale">
              <b-td></b-td>
              <b-td></b-td>
              <b-td></b-td>
              <b-td>{{ computedTotalGeneral.total_surface_101 }} m²</b-td>
              <b-td>{{ computedTotalGeneral.total_surface_102 }} m²</b-td>
              <b-td>{{ computedTotalGeneral.total_surface_103 }} m²</b-td>
              <b-td>{{ computedTotalGeneral.montant_101 }} €</b-td>
              <b-td>{{ computedTotalGeneral.montant_102 }} €</b-td>
              <b-td>{{ computedTotalGeneral.montant_103 }} €</b-td>
              <b-td>{{ computedTotalGeneral.total }} €</b-td>
              <b-td>{{ computedTotalGeneral.pourcentage }} %</b-td>
              <b-td>{{ computedTotalGeneral.total_tasks }}</b-td>
              <b-td>{{ computedTotalGeneral.totalm2_totalEuro }} €</b-td>
            </b-tr>
          </template>
        </b-table>

        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowValidationCoutRegie"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'Coût-des-régies',
  data() {
    return {
      filterRegie: { name: 'tous', id: null },
      ShowALL: false,
      filteryear: null,
      filterSemaine: null,
      ListYear: [],
      ListWeek: [],
      page: 1,
      perPage: 10,
      fields: [
        { key: 'regie_name', label: 'Nom' },
        { key: 'statut', label: 'Statut' },
        { key: 'semaine', label: 'Semaine' },
        { key: 'total_surface_101', label: 'Total surface 101' },
        { key: 'total_surface_102', label: 'Total surface 102' },
        { key: 'total_surface_103', label: 'Total surface 103' },
        { key: 'montant_101', label: 'Montant 101' },
        { key: 'montant_102', label: 'Montant 102' },
        { key: 'montant_103', label: 'Montant 103' },
        { key: 'total', label: 'Total' },
        { key: 'pourcentage', label: 'Pourcentage' },
        { key: 'total_tasks', label: 'Nombre des dossiers' },
        { key: 'totalm2_totalEuro', label: 'Coût moyen ' }
      ],
      validationList: [
        { value: null, text: 'tous' },
        { value: 'en attend', text: 'En cours de validation' },
        { value: 'refuse', text: 'Non validé' },
        { value: 'valid', text: 'Validé' },
        { value: 'encore de paiement', text: 'En cours de paiement' },
        { value: 'paye', text: 'Payé' }
      ],
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'fetchValidationCout',
      'validationFactureRegie',
      'fetchAllRegieForAdmin',
      'exportFileValidationCoutRegie'
    ]),
    rowClass(item, type) {
      return 'ligneNormale';
    },
    handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.page = 1;
      sessionStorage.setItem('page-gestion-facture-admin', this.page);
      localStorage.setItem(
        'validation-cout-regie',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterRegie: this.filterRegie
        })
      );
      this.fetchValidationCout({
        semaine: this.filterSemaine,
        year: this.filteryear,
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage
      });
    },
    handleChangeYear() {
      var Filters = JSON.parse(localStorage.getItem('validation-cout-regie'));
      Filters.filteryear = this.filteryear;
      localStorage.setItem('validation-cout-regie', JSON.stringify(Filters));
      this.page = 1;
      sessionStorage.setItem('page-gestion-facture-admin', this.page);
      var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      this.fetchValidationCout({
        semaine: this.filterSemaine,
        year: this.filteryear,
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage
      });
    },
    pagination(paginate) {
      this.page = paginate;
      sessionStorage.setItem('page-gestion-facture-admin', this.page);
      this.fetchValidationCout({
        semaine: this.filterSemaine,
        year: this.filteryear,
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage
      });
      this.ShowALL = false;
    },
    changePerPage() {
      this.page = 1;
      sessionStorage.setItem('page-gestion-facture-admin', this.page);
      this.fetchValidationCout({
        semaine: this.filterSemaine,
        year: this.filteryear,
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage
      });
      this.ShowALL = false;
    },
    exportFiles() {
      this.exportFileValidationCoutRegie({
        semaine: this.filterSemaine,
        year: this.filteryear,
        filterRegie: this.filterRegie
      });
    }
  },

  computed: {
    ...mapGetters([
      'getLoadingValidationCoutRegie',
      'getTotalRowValidationCoutRegie',
      'getRegieValidationCoutTable',
      'getAllRegie',
      'cantExport'
    ]),
    ComputedListWeek() {
      return this.ListWeek;
    },
    computedRegieFacture() {
      return this.getRegieValidationCoutTable;
    },
    computedgetAllRegie() {
      return [{ id: null, name: 'tous' }, ...this.getAllRegie];
    },
    computedTotalGeneral() {
      var total_surface_101 = 0;
      var total_surface_102 = 0;
      var total_surface_103 = 0;
      var montant_101 = 0;
      var montant_102 = 0;
      var montant_103 = 0;
      var total = 0;
      var pourcentage = 0;
      var total_tasks = 0;
      var totalm2_totalEuro = 0;

      for (let i = 0; i < this.getRegieValidationCoutTable.length; i++) {
        total_surface_101 =
          total_surface_101 +
          parseFloat(this.getRegieValidationCoutTable[i].total_surface_101);
        total_surface_102 =
          total_surface_102 +
          parseFloat(this.getRegieValidationCoutTable[i].total_surface_102);
        total_surface_103 =
          total_surface_103 +
          parseFloat(this.getRegieValidationCoutTable[i].total_surface_103);
        montant_101 =
          montant_101 +
          parseFloat(this.getRegieValidationCoutTable[i].montant_101);
        montant_102 =
          montant_102 +
          parseFloat(this.getRegieValidationCoutTable[i].montant_102);
        montant_103 =
          montant_103 +
          parseFloat(this.getRegieValidationCoutTable[i].montant_103);
        total = total + parseFloat(this.getRegieValidationCoutTable[i].total);
        pourcentage =
          pourcentage +
          parseFloat(this.getRegieValidationCoutTable[i].pourcentage);
        total_tasks =
          total_tasks +
          parseFloat(this.getRegieValidationCoutTable[i].total_tasks);
        totalm2_totalEuro =
          totalm2_totalEuro +
          parseFloat(this.getRegieValidationCoutTable[i].totalm2_totalEuro);
      }

      return {
        total_surface_101: total_surface_101,
        total_surface_102: total_surface_102,
        total_surface_103: total_surface_103,
        montant_101: montant_101,
        montant_102: montant_102,
        montant_103: montant_103,
        total: total,
        pourcentage: pourcentage,
        total_tasks: total_tasks,
        totalm2_totalEuro: totalm2_totalEuro
      };
    }
  },
  filters: {
    statutFormat: value => {
      switch (value) {
        case 'valid':
          return 'Validé';
        case 'en attend':
          return 'En cours de validation';
        case 'refuse':
          return 'Non validé';
        case 'encore de paiement':
          return 'En cours de paiement';
        case 'paye':
          return 'Payé';
        default:
          return value;
      }
    }
  },
  components: {
    SelectComponent: () => import('../SelectComponent')
  },
  mounted() {
    var courantYear = moment().year();
    if (localStorage.getItem('validation-cout-regie')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('validation-cout-regie')
      ).filteryear;
      this.filterSemaine = JSON.parse(
        localStorage.getItem('validation-cout-regie')
      ).filterSemaine;
      this.filterRegie = JSON.parse(
        localStorage.getItem('validation-cout-regie')
      ).filterRegie;
    } else {
      this.filteryear = courantYear;
      this.filterSemaine = 's' + moment().isoWeek();
      localStorage.setItem(
        'validation-cout-regie',
        JSON.stringify({
          filteryear: this.filteryear,
          filterSemaine: this.filterSemaine,
          filterRegie: this.filterRegie
        })
      );
    }
    if (sessionStorage.getItem('page-gestion-facture-admin')) {
      this.page = sessionStorage.getItem('page-gestion-facture-admin');
    } else {
      sessionStorage.setItem('page-gestion-facture-admin', this.page);
    }
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeek.push({ value: 's' + week, text: week });
    }
    this.fetchAllRegieForAdmin();
    this.fetchValidationCout({
      semaine: this.filterSemaine,
      year: this.filteryear,
      filterRegie: this.filterRegie,
      page: this.page,
      per_page: this.perPage
    });
  }
};
</script>

<style scoped lang="scss">
.contentValidationRegie {
  padding: 10px !important;
  margin: 0px !important;
  height: calc(100vh - 110px);
  display: block;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .body-box-rapport {
    height: calc(100vh - 175px);
  }
}
.tableValidationCouRegie {
  max-height: calc(100vh - 210px) !important;
  height: calc(100vh - 266px) !important;
  margin-bottom: 0px;
  td {
    padding: 5px;
  }
}
</style>
<style lang="scss">
.tableValidationCouRegie {
  td {
    padding: 5px;
  }
}
</style>
